import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

const UpcomingEvents = () => {
    // const [activeTab, setActiveTab] = useState('exams');
    const intl = useIntl()
    
    const fbEvents = useStaticQuery(graphql`
        query MyQuery {
            allFacebook {
                nodes {
                posts {
                    data {
                    id
                    message
                    created_time
                    }
                }
                events {
                    
                    data {
                    cover {
                        source
                    }
                    id
                    description
                    name
                    attending_count
                    }
                }
                }
            }
          }     
       `)


    const  newsList = fbEvents.allFacebook.nodes[0].events.data.map((event) => { 
        return({
            id: event.id,
            type: 'event',
            title: event.name,
            content: event.description,
            imageUrl: event.cover && event.cover.source
        } 
    )})

    const openFacebookEvent = (itemId) => {
        window.open(`https://www.facebook.com/events/${itemId}`, "_blank") //to open new page
    }
    
    return (
        
            <div className="grid grid-cols-12 gap-4 mx-auto max-w-screen-xl py-5 overflow-x-hidden">
                
                <div className="col-span-12 md:col-span-4" >
                    <h2 className="font-heading text-3xl text-white ">
                     {intl.formatMessage({ id: "eventsAndNews" })}
                    </h2>
                </div>
                {/* <div className="col-span-12 md:col-start-1 md:col-end-4 text-center" >
                    <h2 className="font-heading text-2xl">
                        News
                    </h2>
                </div> */}

                <div className="col-span-12 flex overflow-hidden" >
               
                    {newsList.slice(0, 4).map((newsItem,index) => {return(
                        <div  key={index} className="w-full px-3 pb-5 max-w-sm flex-shrink-0 ">
                            <div  className={`inline-flex shadow-lg h-48 relative ${index > 2?'opacity-25':''}`}>
                                <div className="w-full w-2/3 px-4 py-4  bg-white rounded rounded-r-none ">
                                    <div className="flex-row items-center h-24 overflow-hidden mb-5  ">
                                        <h2 className="text-xl text-gray-800 font-medium mr-auto">{newsItem.title}</h2>
                                        <div className="text-sm text-gray-500"></div>
                                        <p className="text-sm text-gray-800 ">{newsItem.content}</p> 
                                    </div>

                                    <button 
                                    onClick={()=> openFacebookEvent(newsItem.id)} 
                                    className="bg-transparent hover:bg-blue-500 text-sm text-blue-700 font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded">
                                       
                                        <svg className="fill-current w-4 h-4 mr-3 mb-1 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.26 13a2 2 0 0 1 .01-2.01A3 3 0 0 0 9 5H5a3 3 0 0 0 0 6h.08a6.06 6.06 0 0 0 0 2H5A5 5 0 0 1 5 3h4a5 5 0 0 1 .26 10zm1.48-6a2 2 0 0 1-.01 2.01A3 3 0 0 0 11 15h4a3 3 0 0 0 0-6h-.08a6.06 6.06 0 0 0 0-2H15a5 5 0 0 1 0 10h-4a5 5 0 0 1-.26-10z"/></svg>
                                        {intl.formatMessage({ id: "seeOnFacebook" })}
                                    </button>
                                </div>
                                <img className="h-full w-1/3 object-cover rounded rounded-l-none pb-5/6" src={newsItem.imageUrl} alt={newsItem.title} />
                            </div>
                        </div>
                    )})}
                </div>
            </div> 
         
    )
}

export default UpcomingEvents;